<template>
  <div class="row">
   <div class="col-md-4">
      <card class="deorgcard" v-if="loaded">
        <div>
          <div class="deorgcardheader">Impact Risk Solutions</div>
          <img
            src="static/img/organisation.svg"
            style="width: 190px; height: 190px; margin-top: 50px"
          />
          <div>
            <button
              style="height: 40px; margin-top: 25px"
              type="button"
              @click="defaultOrganisationAssessments"
              class="btn btn-fill btn-info btn-wd"
            >
              <span class="btn-label">
                <i class="fa fa-diagnoses"></i>
              </span>
              Assessments
            </button>
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-4">
      <card class="deorgcard" v-if="loaded">
        <div>
          <div class="deorgcardheader">Business Units</div>
          <img src="static/img/businessunit.svg" class="deorgimage" />
          <div>
            <fg-input>
              <el-select
                name="business units"
                class="select-default"
                style="width: 70%"
                v-model="model.businessUnitId"
                placeholder="Select Business Unit"
                v-on:input="ddlBusinessUnitChange"
              >
                <el-option
                  class="select-default"
                  v-for="item in businessUnits"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </fg-input>

            <button
              style="height: 40px; margin-top: 20px"
              type="button"
              id="btnbusinessUnitAssessments"
              :disabled="btnBusinessUnit == false"
              @click="businessUnitAssessments"
              class="btn btn-fill btn-info btn-wd"
            >
              <span class="btn-label">
                <i class="fa fa-diagnoses"></i>
              </span>
              Assessments
            </button>
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-4">
      <card class="deorgcard" v-if="loaded">
        <div>
          <div class="deorgcardheader">Vendors</div>
          <img src="static/img/vendor.svg" class="deorgimage" />
          <div>
            <fg-input>
              <el-select
                name="vendors"
                class="select-default"
                style="width: 70%"
                v-model="model.vendorId"
                placeholder="Select Vendor"
                v-on:input="ddlVendorChange"
              >
                <el-option
                  class="select-default"
                  v-for="item in vendors"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </fg-input>
            <button
              style="height: 40px; margin-top: 20px"
              type="button"
              @click="vendorAssessments"
              class="btn btn-fill btn-info btn-wd"
              :disabled="btnVendor == false"
            >
              <span class="btn-label">
                <i class="fa fa-diagnoses"></i>
              </span>
              Assessments
            </button>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  data() {
    return {
      model: {
        businessUnitId: null,
        vendorsId: null,
      },
      businessUnits: [],
      vendors: [],
      btnBusinessUnit: false,
      btnVendor: false,
      loaded: false,
    };
  },

  created() {
    let self = this;
    self.getAllActiveBusinessUnits();
    self.getAllActiveVendors();
  },

  methods: {
    getAllActiveBusinessUnits() {
      let self = this;
      self.$store.state.services.organisationService
        .getAll({
          params: {
            OrganisationType: "Business Unit",
          },
        })
        .then((r) => {
          self.businessUnits = r.data;
        })
        .catch((err) => console.log(err));
    },
    getAllActiveVendors() {
      let self = this;
      self.$store.state.services.organisationService
        .getAll({
          params: {
            OrganisationType: "Vendor",
          },
        })
        .then((r) => {
          self.vendors = r.data;
          self.loaded = true;
        })
        .catch((err) => console.log(err));
    },
    defaultOrganisationAssessments() {
      let self = this;
      //Add user's default organisation
      //self.$router.push("/designeffectiveness/1/deassessments");
      self.$emit("update:organisationId", "1");
    },
    businessUnitAssessments() {
      let self = this;
      //self.$router.push("/designeffectiveness/" + self.model.businessUnitId + "/deassessments");
      self.$emit("update:organisationId", self.model.businessUnitId);
    },
    vendorAssessments() {
      let self = this;
      //self.$router.push("/designeffectiveness/" + self.model.vendorsId + "/deassessments");
      self.$emit("update:organisationId", self.model.vendorsId);
    },
    ddlBusinessUnitChange(value) {
      let self = this;
      self.model.businessUnitId = value;
      self.btnBusinessUnit = true;
    },
    ddlVendorChange(value) {
      let self = this;
      self.model.vendorsId = value;
      self.btnVendor = true;
    },
  },
};
</script>
<style>
.deorgcardheader {
  font-size: 20px;
  color: grey;
  font-weight: bold;
  margin-top: 15px;
}
.deorgcard {
  text-align: center;
  height: 400px;
}
.deorgimage {
  width: 160px;
  height: 160px;
  margin-top: 30px;
}
</style>