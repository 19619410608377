<template>
  <div class="container-fluid">
    <breadcrumb>
      <img
        src="static/img/organisation.svg"
        class="breadcrumb-image"
      />
      <breadcrumb-item active>Select Entity</breadcrumb-item>
    </breadcrumb>
    <organisation-select
      @update:organisationId="selectOrganisation"
    ></organisation-select>
  </div>
</template>
<script>
import { Breadcrumb, BreadcrumbItem } from "src/components/index";
import OrganisationSelect from "src/components/OrganisationSelect.vue";

export default {
  components: {
    Breadcrumb,
    BreadcrumbItem,
    OrganisationSelect,
  },

  methods: {
    selectOrganisation(value) {
      let self = this;
      self.$router.push("/operationaleffectiveness/" + value + "/oeassessments");
    },
  },
};
</script>